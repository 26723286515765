const Name = () => (
  <div className="Name">
    <h1>Nikolas Zagarella</h1>
    <h3>
      <span>Full Stack Developer</span>
    </h3>
  </div>
);

export default Name;
